<template>
  <div class="container-fluid header">
    <div class="container-fluid header__title">
      <div class="container">
        <div class="row mb-4 mt-5">
          <div class="containerImg">
            <img
              src="../assets/logo-micrositio-mexicodatecuenta.svg"
              alt="Logo México date cuenta"
            />
          </div>
          <div class="row">
            <h1>
              Gasto en la
              <span class="fw-bolder">Alerta de Género contra las Mujeres</span>
              (AVGM) e
              <br />
              incidencia delictiva contra las mujeres en México
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid header__nav">
      <nav class="navbar navbar-expand-md navbar-dark container">
        <h2 class="navbar-brand" v-if="windowWidth< 768">{{ section_selected }}</h2>
        <!-- {{windowWidth}} -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navHeader"
          aria-controls="navHeader"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navHeader">
          <div class="row header__section navbar-nav">
            <div
              class="col-md-3 container__routerLink"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
            >
              <router-link to="/">
                <h2
                  :class="{
                    header__sectionSelected:
                      section_selected == 'Gasto por entidad',
                  }"
                >
                  Gasto por entidad
                </h2>
              </router-link>
            </div>
            <div
              class="col-md-3 container__routerLink"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
            >
              <router-link to="/EstadosAlertaActiva">
                <h2
                  :class="{
                    header__sectionSelected:
                      section_selected == 'Entidades con AVGM',
                  }"
                >
                  Entidades con AVGM
                </h2>
              </router-link>
            </div>
            <div
              class="col-md-3 container__routerLink"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
            >
              <router-link to="/DescargaDatos">
                <h2
                  :class="{
                    header__sectionSelected:
                      section_selected == 'Metodología y descarga de datos',
                  }"
                >
                  Metodología y descarga de datos
                </h2>
              </router-link>
            </div>
            <div
              class="col-md-3 container__routerLink"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
            >
              <router-link to="/Feminicidios">
                <h2
                  :class="{
                    header__sectionSelected: section_selected == 'Feminicidios',
                  }"
                >
                  Feminicidios
                </h2>
              </router-link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { ref } from "vue"
export default {
  setup(){
    let windowWidth = ref(window.innerWidth)
  const onWidthChange = () => windowWidth.value = window.innerWidth
  return {
    windowWidth,
    onWidthChange
  }
  },

  mounted(){
    window.addEventListener('resize', this.onWidthChange )
  },
  unmounted(){window.removeEventListener('resize', this.onWidthChange)},
  name: "Header",
  props: {
    section_selected: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  padding: 0;
  background-color: #5e389b;
  color: #ffffff;
  margin-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
}
.container__routerLink {
  display: flex;
  justify-content: center;
}
.header__title {
  background-color: #5e389b;
}
.header__title .containerImg {
  width: 65%;
}

.header .header__nav {
  background-color: #6f46bc;
  width: 100%;
  align-self: flex-end;
}
.header__section {
  padding: 10px;
}
.header__section > div {
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.header__section div a {
  text-decoration: none;
}

h1 {
  color: #ffffff;
  text-align: right;
  font-size: 12px;
}
h2 {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin: 0 !important;
  padding: 5px;
  align-self: flex-end;
  border-radius: 20px;
}
h3 {
  font-size: 16px;
}
.header__sectionSelected {
  background-color: #c7a4fc;
  color: #492973;
}

@media (min-width: 768px) {
  h1 {
    font-size: 27px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 18px;
  }
  @media (min-width: 992px) {
    h1 {
      font-size: 34px;
    }
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 28px;
    }
  }
}
</style>