// import { createRouter, createWebHashHistory } from 'vue-router';
import { createRouter, createWebHistory } from "vue-router";
import GastoEstado from "./views/GastoEstado.vue";

const routes = [
  {
    path: "/",
    name: "GastoEstado",
    component: GastoEstado,
  },
  {
    path: "/EstadosAlertaActiva",
    name: "EstadosAlertaActiva",
    component: () =>
      import(/* webpackChunkName: "about" */ "./views/EstadosAlertaActiva.vue"),
  },
  {
    path: "/DescargaDatos",
    name: "DescargaDatos",
    component: () =>
      import(/* webpackChunkName: "about" */ "./views/DescargaDatos.vue"),
  },

  {
    path: "/Feminicidios",
    name: "Feminicidios",
    component: () =>
      import(/* webpackChunkName: "about" */ "./views/Feminicidios.vue"),
  },
  {
    path: "/AcercaDe",
    name: "AcercaDe",
    component: () =>
      import(/* webpackChunkName: "about" */ "./views/AcercaDe.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
