<template>
  <div class="container">
    <!-- <h1>Gastos total en la AVGM en México</h1>
    <div class="row">
      <div class="col-4"><h2>Total gasto:</h2></div>
      <div class="col-4"><h2>Año</h2></div>
    </div>
    <div class="row">
      <div class="col-4 body2">2.3 millones de pesos</div>
      <div class="col-4">
        <select
          name="selec_año_nacional"
          id="selec_año_nacional"
          class="select body1"
        >
          <option value="2018">2018</option>
        </select>
      </div>
    </div> -->
    <h1>
      Gasto reportado por<span class="entidad_name">_</span> para atender la
      AVGM
    </h1>
    <div class="row mb-4">
      <div class="col-4">
        <h2><label for="estados_select">Entidad</label></h2>
      </div>
      <div class="col-4"><h2>Total reportado:</h2></div>
      <div class="col-4">
        <h2><label for="año_select">Año</label></h2>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-4">
        <select
          name="estados_select_name"
          id="estados_select"
          class="select body2"
        ></select>
      </div>
      <div class="col-4 body2"><span class="montoSankey">_</span> pesos</div>
      <div class="col-4">
        <select
          name="año_select_name"
          id="año_select"
          class="select body2"
        ></select>
      </div>
    </div>
    <!-- graf -->
    <div class="row">
      <div class="col-lg-12">
        <p class="body1">
          Los gastos reportados por esta entidad en respuesta a solicitudes de
          acceso a la información pública para atender la AVGM
          <span class="current_year">_</span> fueron de
          <span class="montoSankey">_</span> pesos
        </p>
      </div>
      <div class="col-lg-12">
        <div id="viz-gastos-entidad">
          <span class="note"
            >Para mejor visualización arrastra la categoría hacia arriba o
            abajo.</span
          >
        </div>
      </div>
      <div class="col-lg-12">
        <p class="body1">
          Esta gráfica muestra la clasificación de los recursos públicos
          destinados a la Alerta de Género reportados por el estado. Las
          categorías son las estipuladas en la declaratoria de Alerta de Género
          decretada por la CONAVIM. Ademas fue incluido el rubro "sin categoria"
          para los gastos que no correspondan a ninguna de estas.
        </p>
      </div>
    </div>
    <!-- tab -->
    <div class="row containerGridGastoAvgm">
      <p>
        <label for="searchGastoAvgmId"
          >Buscar por tipo de gasto, concepto, monto o dependencia del
          estado</label
        >
      </p>
      <input
        type="text"
        id="searchGastoAvgmId"
        class="searchGastoAvgm"
        placeholder="Buscar"
        v-model="valSearch"
      />
      <div class="containerTable">
        <table id="gridGastoAvgm">
          <thead>
            <tr>
              <th><h2>Estado</h2></th>
              <th><h2>Dependencia</h2></th>
              <th><h2>Tipo de gasto</h2></th>
              <th><h2>Categoría</h2></th>
              <th><h2>Concepto</h2></th>
              <th><h2>Monto</h2></th>
              <th><h2>Año</h2></th>
              <th><h2>Factura</h2></th>
              <th><h2>Contrato</h2></th>
              <th><h2>Orden de compra</h2></th>
              <th><h2>Proveedor</h2></th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import * as d3 from "d3v4";
// añade sankey a d3
d3.sankey = function () {
  var sankey = {},
    nodeWidth = 24,
    nodePadding = 8,
    size = [1, 1],
    nodes = [],
    links = [];

  sankey.nodeWidth = function (_) {
    if (!arguments.length) return nodeWidth;
    nodeWidth = +_;
    return sankey;
  };

  sankey.nodePadding = function (_) {
    if (!arguments.length) return nodePadding;
    nodePadding = +_;
    return sankey;
  };

  sankey.nodes = function (_) {
    if (!arguments.length) return nodes;
    nodes = _;
    return sankey;
  };

  sankey.links = function (_) {
    if (!arguments.length) return links;
    links = _;
    return sankey;
  };

  sankey.size = function (_) {
    if (!arguments.length) return size;
    size = _;
    return sankey;
  };

  sankey.layout = function (iterations) {
    computeNodeLinks();
    computeNodeValues();
    computeNodeBreadths();
    computeNodeDepths(iterations);
    computeLinkDepths();
    return sankey;
  };

  sankey.relayout = function () {
    computeLinkDepths();
    return sankey;
  };

  sankey.link = function () {
    var curvature = 0.5;

    function link(d) {
      var x0 = d.source.x + d.source.dx,
        x1 = d.target.x,
        xi = d3.interpolateNumber(x0, x1),
        x2 = xi(curvature),
        x3 = xi(1 - curvature),
        y0 = d.source.y + d.sy + d.dy / 2,
        y1 = d.target.y + d.ty + d.dy / 2;
      return (
        "M" +
        x0 +
        "," +
        y0 +
        "C" +
        x2 +
        "," +
        y0 +
        " " +
        x3 +
        "," +
        y1 +
        " " +
        x1 +
        "," +
        y1
      );
    }

    link.curvature = function (_) {
      if (!arguments.length) return curvature;
      curvature = +_;
      return link;
    };

    return link;
  };

  // Populate the sourceLinks and targetLinks for each node.
  // Also, if the source and target are not objects, assume they are indices.
  function computeNodeLinks() {
    nodes.forEach(function (node) {
      node.sourceLinks = [];
      node.targetLinks = [];
    });
    links.forEach(function (link) {
      var source = link.source,
        target = link.target;
      if (typeof source === "number") source = link.source = nodes[link.source];
      if (typeof target === "number") target = link.target = nodes[link.target];
      source.sourceLinks.push(link);
      target.targetLinks.push(link);
    });
  }

  // Compute the value (size) of each node by summing the associated links.
  function computeNodeValues() {
    nodes.forEach(function (node) {
      node.value = Math.max(
        d3.sum(node.sourceLinks, value),
        d3.sum(node.targetLinks, value)
      );
    });
  }

  // Iteratively assign the breadth (x-position) for each node.
  // Nodes are assigned the maximum breadth of incoming neighbors plus one;
  // nodes with no incoming links are assigned breadth zero, while
  // nodes with no outgoing links are assigned the maximum breadth.
  function computeNodeBreadths() {
    var remainingNodes = nodes,
      nextNodes,
      x = 0;

    while (remainingNodes.length) {
      nextNodes = [];
      remainingNodes.forEach(function (node) {
        node.x = x;
        node.dx = nodeWidth;
        node.sourceLinks.forEach(function (link) {
          if (nextNodes.indexOf(link.target) < 0) {
            nextNodes.push(link.target);
          }
        });
      });
      remainingNodes = nextNodes;
      ++x;
    }

    moveSinksRight(x);
    scaleNodeBreadths((size[0] - nodeWidth) / (x - 1));
  }

  function moveSinksRight(x) {
    nodes.forEach(function (node) {
      if (!node.sourceLinks.length) {
        node.x = x - 1;
      }
    });
  }

  function scaleNodeBreadths(kx) {
    nodes.forEach(function (node) {
      node.x *= kx;
    });
  }

  function computeNodeDepths(iterations) {
    var nodesByBreadth = d3
      .nest()
      .key(function (d) {
        return d.x;
      })
      .sortKeys(d3.ascending)
      .entries(nodes)
      .map(function (d) {
        return d.values;
      });

    //
    initializeNodeDepth();
    resolveCollisions();
    for (var alpha = 1; iterations > 0; --iterations) {
      relaxRightToLeft((alpha *= 0.99));
      resolveCollisions();
      relaxLeftToRight(alpha);
      resolveCollisions();
    }

    function initializeNodeDepth() {
      var ky = d3.min(nodesByBreadth, function (nodes) {
        return (
          (size[1] - (nodes.length - 1) * nodePadding) / d3.sum(nodes, value)
        );
      });

      nodesByBreadth.forEach(function (nodes) {
        nodes.forEach(function (node, i) {
          node.y = i;
          node.dy = node.value * ky;
        });
      });

      links.forEach(function (link) {
        link.dy = link.value * ky;
      });
    }

    function relaxLeftToRight(alpha) {
      nodesByBreadth.forEach(function (nodes) {
        nodes.forEach(function (node) {
          if (node.targetLinks.length) {
            var y =
              d3.sum(node.targetLinks, weightedSource) /
              d3.sum(node.targetLinks, value);
            node.y += (y - center(node)) * alpha;
          }
        });
      });

      function weightedSource(link) {
        return center(link.source) * link.value;
      }
    }

    function relaxRightToLeft(alpha) {
      nodesByBreadth
        .slice()
        .reverse()
        .forEach(function (nodes) {
          nodes.forEach(function (node) {
            if (node.sourceLinks.length) {
              var y =
                d3.sum(node.sourceLinks, weightedTarget) /
                d3.sum(node.sourceLinks, value);
              node.y += (y - center(node)) * alpha;
            }
          });
        });

      function weightedTarget(link) {
        return center(link.target) * link.value;
      }
    }

    function resolveCollisions() {
      nodesByBreadth.forEach(function (nodes) {
        var node,
          dy,
          y0 = 0,
          n = nodes.length,
          i;

        // Push any overlapping nodes down.
        nodes.sort(ascendingDepth);
        for (i = 0; i < n; ++i) {
          node = nodes[i];
          dy = y0 - node.y;
          if (dy > 0) node.y += dy;
          y0 = node.y + node.dy + nodePadding;
        }

        // If the bottommost node goes outside the bounds, push it back up.
        dy = y0 - nodePadding - size[1];
        if (dy > 0) {
          y0 = node.y -= dy;

          // Push any overlapping nodes back up.
          for (i = n - 2; i >= 0; --i) {
            node = nodes[i];
            dy = node.y + node.dy + nodePadding - y0;
            if (dy > 0) node.y -= dy;
            y0 = node.y;
          }
        }
      });
    }

    function ascendingDepth(a, b) {
      return a.y - b.y;
    }
  }

  function computeLinkDepths() {
    nodes.forEach(function (node) {
      node.sourceLinks.sort(ascendingTargetDepth);
      node.targetLinks.sort(ascendingSourceDepth);
    });
    nodes.forEach(function (node) {
      var sy = 0,
        ty = 0;
      node.sourceLinks.forEach(function (link) {
        link.sy = sy;
        sy += link.dy;
      });
      node.targetLinks.forEach(function (link) {
        link.ty = ty;
        ty += link.dy;
      });
    });

    function ascendingSourceDepth(a, b) {
      return a.source.y - b.source.y;
    }

    function ascendingTargetDepth(a, b) {
      return a.target.y - b.target.y;
    }
  }

  function center(node) {
    return node.y + node.dy / 2;
  }

  function value(link) {
    return link.value;
  }

  return sankey;
};
// formatos a usar
var formatNumber = d3.format(",.2f");
var formatNumberComa = d3.format(",");
// hover sankey
var divtool = d3
  .select("body")
  .append("div")
  .attr("class", "tooltip")
  .style("opacity", 0);
// colores a usar en sankey
let color = d3
  .scaleOrdinal()
  .range([
    "#8DD3C7",
    "#5C6BC0",
    "#BEBADA",
    "#FB8072",
    "#80B1D3",
    "#FDB462",
    "#B3DE69",
    "#FCCDE5",
    "#D9D9D9",
    "#BC80BD",
    "#CCEBC5",
    "#AB47BC",
    "#FFFB23",
    "#29B6F6",
    "#26A69A",
    "#66BB6A",
    "#9CCC65",
    "#D4E157",
    "#FFEE58",
    "#FFA726",
    "#FF7043",
    "#8D6E63",
    "#BDBDBD",
    "#78909C",
  ]);
export default {
  props: {
    change_section: Function,
  },
  setup(props) {
    props.change_section("Gasto por entidad");
    const valSearch = ref("");
    return { valSearch };
  },
  async mounted() {
    // añade dropdowns y actualiza sankey
    await d3.csv(
      "/monto_por_estado_año_categoria_tipo_gasto.csv",
      (err, data) => {
        // añade estados a dropdown
        (function () {
          d3.select("#estados_select").selectAll("option").remove();
          let estados = [...new Set(data.map((d) => d.Estado))];
          let estadosSelect = d3
            .select("#estados_select")
            .selectAll("option")
            .data(estados);
          estadosSelect
            .enter()
            .append("option")
            .attr("value", (d) => d)
            .text((d) => d)
            .attr("class", "body2");
        })();
        // Actualiza sankey y los años al cambiar de estado
        d3.select("#estados_select").on("change", () => updateYears(data));
        updateYears(data);
        let yearsArr = [];
        // funcion para pintar años segun el estado seleccionado
        function updateYears(data) {
          d3.select("#año_select").selectAll("option").remove();
          let estado = d3.select("#estados_select").property("value");
          data = data.filter((d) => d.Estado == estado);
          let years = [...new Set(data.map((d) => d.Año)), "todos"].reverse();
          yearsArr = years;
          let yearSelect = d3
            .select("#año_select")
            .selectAll("option")
            .data(years);
          yearSelect
            .enter()
            .append("option")
            .attr("value", (d) => d)
            .text((d) => d)
            .attr("class", "body2");

          let year = d3.select("#año_select").property("value");
          d3.select("#viz-gastos-entidad").select("svg").remove();
          avgmSankey(estado, year, yearsArr);
        }
        // Actualiza el sankey al cambiar de año
        d3.select("#año_select").on("change", function () {
          let year = d3.select(this).property("value");
          let estado = d3.select("#estados_select").property("value");
          d3.select("#viz-gastos-entidad").select("svg").remove();
          avgmSankey(estado, year, yearsArr);
        });
      }
    );
    // funcion para crear sankey
    function avgmSankey(estado, year, yearsArr) {
      let todos = "_";
      year == "todos" ? (todos = "_") : (todos = "_año_");
      d3.csv(
        `/monto_por_estado${todos}categoria_tipo_gasto.csv`,
        function (error, data) {
          // set the dimensions and margins of the graph
          const cotainerSvg = document.getElementById("viz-gastos-entidad");
          let heightRes = 600;
          if (window.screen.width < 700) {
            heightRes = 400;
          }
          if (window.screen.width < 350) {
            heightRes = 250;
          }
          var margin = { top: 10, right: 0, bottom: 10, left: 0 },
            w = cotainerSvg.clientWidth,
            h = heightRes,
            width = w - margin.left - margin.right,
            height = h - margin.top - margin.bottom;

          let format = function (d) {
            return "$" + formatNumber(d);
          };

          // append the svg canvas to the page
          var svg = d3
            .select("#viz-gastos-entidad")
            .append("svg")
            .attr("viewBox", "0 0 " + w + " " + h)
            .attr("width", "100%")
            .attr("height", h + margin.top + margin.bottom)
            .classed("svg-content", true);

          // Set the sankey diagram properties
          var sankey = d3
            .sankey()
            .nodeWidth(15)
            .nodePadding(5)
            .size([w, height]);

          var path = sankey.link();
          data = data.filter((d) => d.Estado === estado);
          filterEntidad(year, data);

          function filterEntidad(year, dataEstidad) {
            /** Removes */
            svg.selectAll("path.link").remove();
            svg.selectAll("g.node").remove();

            d3.selectAll("span.entidad_name").text(" " + estado + " ");
            if (year !== "todos") {
              d3.selectAll("span.current_year").text(" en " + year + " ");
              dataEstidad = dataEstidad.filter((d) => d.Año === year);
            } else {
              let ultimo = yearsArr.length;
              d3.selectAll("span.current_year").text(
                " de " + yearsArr[1] + " a " + yearsArr[ultimo - 1] + " "
              );
            }
            function deleteDecimal0(num) {
              let decimal = +num - Math.floor(+num);
              if (decimal == 0) {
                return Math.trunc(num);
              }
              return num;
            }
            let sumMonto = 0;
            dataEstidad.forEach((d) => {
              sumMonto = sumMonto + +d.Monto;
            });
            sumMonto = sumMonto.toFixed(1);
            (function () {
              let sumMontoArr = Math.trunc(sumMonto).toString().split("");
              if (sumMontoArr.length > 6) {
                let digitsAfterMillion = sumMontoArr.length - 5;
                sumMontoArr = sumMontoArr.slice(0, digitsAfterMillion);
                sumMontoArr.splice(-1, 0, ".");
                sumMontoArr = sumMontoArr.join("");
                sumMontoArr = deleteDecimal0(sumMontoArr);
                sumMontoArr = formatNumberComa(sumMontoArr);
                let txt = sumMontoArr + " millones de ";
                sumMonto = txt;
              } else {
                sumMonto = formatNumberComa(deleteDecimal0(sumMonto));
              }
            })();

            d3.selectAll("span.montoSankey").text(" " + sumMonto + " ");
            //set up graph in same style as original example but empty
            let graph = { nodes: [], links: [] };

            dataEstidad.forEach(function (d) {
              graph.nodes.push({ name: d["Categoria"] });
              graph.nodes.push({ name: d["Tipo de gasto"] });
              graph.links.push({
                source: d["Categoria"],
                target: d["Tipo de gasto"],
                value: +d.Monto,
              });
            });

            // return only the distinct / unique nodes
            graph.nodes = d3.keys(
              d3
                .nest()
                .key(function (d) {
                  return d.name;
                })
                .map(graph.nodes)
            );

            color.domain(graph.nodes.map((d) => d.name));

            // loop through each link replacing the text with its index from node
            graph.links.forEach(function (d, i) {
              graph.links[i].color = graph.nodes
                .find((d) => d.replace("$", "") == graph.links[i].source)
                .replace("$", "");
              graph.links[i].source = graph.nodes.findIndex(
                (d) => d.replace("$", "") == graph.links[i].source
              );
              graph.links[i].target = graph.nodes.findIndex(
                (d) => d.replace("$", "") == graph.links[i].target
              );
            });

            //now loop through each nodes to make nodes an array of objects
            // rather than an array of strings
            graph.nodes.forEach(function (d, i) {
              graph.nodes[i] = { node: i, name: d.replace("$", "") };
            });

            graph.nodes.splice(graph.nodes.length - 12, 12);

            sankey.nodes(graph.nodes).links(graph.links).layout(4);
            // add in the links
            var link = svg
              .append("g")
              .selectAll(".link")
              .data(graph.links)
              .enter()
              .append("path")
              .attr("class", "link")
              .attr("d", path)
              .style("stroke-width", function (d) {
                return Math.max(1, d.dy);
              })
              .style("stroke", (d) => color(d.color))
              .on("mouseover", function (d) {
                divtool.transition().duration(200).style("opacity", 0.95);
                divtool
                  .html(
                    d.source.name +
                      " → " +
                      d.target.name +
                      "<br>" +
                      "Gasto total: <strong>" +
                      format(d.value) +
                      " </strong>"
                  )
                  .style("left", d3.event.pageX + "px")
                  .style("top", d3.event.pageY - 28 + "px");
              })
              .on("mouseout", function () {
                divtool.transition().duration(200).style("opacity", 0);
              });
            // .sort(function(a, b) { return b.dy - a.dy; })

            // add the link titles
            link.append("title").text(function (d) {
              return d.source + " → " + d.target + "\n" + format(d.value);
            });

            // add in the nodes
            var node = svg
              .append("g")
              .selectAll(".node")
              .data(graph.nodes)
              .enter()
              .append("g")
              .attr("class", "node")
              .attr("transform", function (d) {
                return "translate(" + d.x + "," + d.y + ")";
              })
              .call(
                d3
                  .drag()
                  .subject(function (d) {
                    return d;
                  })
                  .on("start", function () {
                    this.parentNode.appendChild(this);
                  })
                  .on("drag", dragmove)
              );

            // add the rectangles for the nodes
            node
              .append("rect")
              .attr("height", function (d) {
                return d.dy;
              })
              .attr("width", sankey.nodeWidth())
              .style("fill", (d) => color(d.name))
              .style("stroke", (d) => color(d.name));

            // add in the title for the nodes
            node
              .append("text")
              .attr("x", -6)
              .attr("y", (d) => d.dy / 2)
              .attr("dy", ".35em")
              .style("font-family", "'Titillium Web', Helvetica, sans-serif")
              .style("font-size", "10px")
              .attr("text-anchor", "end")
              .text((d) => d.name)
              .filter((d) => d.x < width / 2)
              .attr("x", 6 + sankey.nodeWidth())
              .attr("text-anchor", "start");

            // the function for moving the nodes
            function dragmove(d) {
              d3.select(this).attr(
                "transform",
                "translate(" +
                  d.x +
                  "," +
                  (d.y = Math.max(0, Math.min(height - d.dy, d3.event.y))) +
                  ")"
              );
              sankey.relayout();
              link.attr("d", path);
            }
          }
        }
      );
    }
    await d3.csv("/table_avgm_gasto_chiapas_durango.csv", (err, data) => {
      d3.select(".searchGastoAvgm").on("input", () => {
        dataFilter(this.valSearch);
      });
      const dataFilter = (valFitlter) => {
        d3.select("#gridGastoAvgm").select("tbody").selectAll("tr").remove();
        valFitlter = valFitlter.toLowerCase();
        const filterEach = (d) => {
          let est = d.Estado.toLowerCase().includes(valFitlter);
          let dep = d.Dependencia.toLowerCase().includes(valFitlter);
          let tdg = d["Tipo de gasto"].toLowerCase().includes(valFitlter);
          let cat = d.Categoria.toLowerCase().includes(valFitlter);
          let conc = d.Concepto.toLowerCase().includes(valFitlter);
          let mon = d.Monto.toLowerCase().includes(valFitlter);
          let yea = d.Año.toLowerCase().includes(valFitlter);
          let fac = d.Factura.toLowerCase().includes(valFitlter);
          let cont = d.Contrato.toLowerCase().includes(valFitlter);
          let odc = d["Orden de compra"].toLowerCase().includes(valFitlter);
          let pro = d.Proveedor.toLowerCase().includes(valFitlter);
          let contine = null;
          est ||
          dep ||
          tdg ||
          cat ||
          conc ||
          mon ||
          yea ||
          fac ||
          cont ||
          odc ||
          pro
            ? (contine = true)
            : (contine = false);
          return contine;
        };

        let filter = data.filter(filterEach);
        tableGastoAvgm(filter);
      };
      // llama a la funcion para crear tabla
      tableGastoAvgm(data);
    });
    // funcion para crear tabla
    function tableGastoAvgm(data) {
      let tbodyGastos = d3
        .select("#gridGastoAvgm")
        .select("tbody")
        .selectAll("tr")
        .data(data)
        .enter()
        .append("tr")
        .attr("class", (d, i) => {
          if (i % 2 == 0) {
            return "tr_par";
          }
          return "tr_inpar";
        });
      tbodyGastos.append("td").text((d) => d.Estado);
      tbodyGastos.append("td").text((d) => d.Dependencia);
      tbodyGastos.append("td").text((d) => d["Tipo de gasto"]);
      tbodyGastos.append("td").text((d) => d.Categoria);
      tbodyGastos.append("td").text((d) => d.Concepto);
      tbodyGastos.append("td").text((d) => d.Monto);
      tbodyGastos.append("td").text((d) => d.Año);
      tbodyGastos.append("td").text((d) => d.Factura);
      tbodyGastos.append("td").text((d) => d.Contrato);
      tbodyGastos.append("td").text((d) => d["Orden de compra"]);
      tbodyGastos.append("td").text((d) => d.Proveedor);
    }
  },
  name: "GastoEstado",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.containerGridGastoAvgm {
  margin-top: 50px;
  margin-bottom: 50px;
}

.searchGastoAvgm {
  width: 250px;
  height: 40px;
  border-radius: 30px;
  margin-bottom: 50px;
  outline: none;
}
@media (min-width: 768px) {
  .containerGridGastoAvgm {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
</style>
